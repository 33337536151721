.Clocking,
.widget {
    width: 300px;
    max-width: 80%;
    background-color: #eee;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin: 0 auto;
}

.Clocking button,
.widget button {
    display: block;
    width: 80%;
    margin: 5px auto;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--color-action-primary);
    color: white;
}

.Clocking button.negative {
    background-color: var(--color-action-negative);
}

.Clocking button.negative:hover {
    background-color: var(--color-action-negative-hover);
}

.Clocking input,
.widget input {
    display: block;
    width: 80%;
    margin: 5px auto;
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--color-content-text);
    background-color: var(--color-main-background);
}

.Clocking p.time,
.widget p {
    font-weight: bold;
    color: var(--color-action-secondary);
    margin: 5px 0;
    font-size: 12px;
}

.Clocking .clocking-title,
.widget legend {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--color-content-text);
}

.status-button {
    background-color: black;
    color: white;
    padding: 4px 10px;
    border-radius: 10px;
    font-weight: bold;
}
.status-button.yellow {
    background-color: rgb(225, 165, 0);
}
.status-button.red {
    background-color: rgb(195, 0, 0);
}
.status-button.green {
    background-color: rgb(0, 176, 21);
}
.status-button.grey {
    background-color: rgb(97, 97, 97);
}

.widget fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.widget .form-group {
    margin-bottom: 10px;
}

.widget label {
    display: block;
    margin-bottom: 3px;
    font-weight: bold;
    color: var(--color-content-text);
}

.widget button[type="button"] {
    background-color: var(--color-action-negative);
    border: 1px solid var(--color-button-border);
}

/* Floating widget styles */
.widget-container {
    position: relative;
    height: auto;
    overflow: visible;
    pointer-events:none;
}

.widget {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 10;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 98;
    pointer-events: none;
    border: 1px solid var(--color-border);
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.widget.show {
    opacity: 1;
    transform: translateY(0);
    z-index: 99;
    pointer-events: all;
}

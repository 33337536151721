.ag-theme-quartz {
	--ag-background-color: var(--color-content-background);
	--ag-header-background-color: var(--color-main-background);
	--ag-odd-row-background-color: var(--color-table-alt-row);
	--ag-row-hover-color: var(--color-table-hover-row);
}

.status-button {
	background-color: black;
	color: white;
	padding: 4px 10px;
	border-radius: 3px;
	font-weight: bold;
}

.status-button.open {
	background-color: rgb(225, 165, 0);
}

.status-button.in-progress {
	background-color: rgb(0, 176, 21);
}

.status-button.part-complete {
	background-color: rgb(225, 165, 0);
}

.status-button.complete {
	background-color: rgb(0, 176, 21);
}

.status-button.shipped {
	background-color: rgb(0, 176, 21);
}

.status-button.delivered {
	background-color: rgb(0, 176, 21);
}

.status-button.awaiting-payment {
	background-color: rgb(225, 165, 0);
}

.status-button.invoiced {
	background-color: rgb(0, 176, 21);
}

.status-button.closed {
	background-color: rgb(97, 97, 97);
}

.status-button.on-hold {
	background-color: rgb(225, 165, 0);
}

.status-button.canceled {
	background-color: rgb(195, 0, 0);
}

.status-button.due-soon {
	background-color: orange;
}

.status-button.overdue {
	background-color: red;
}

.materials-cell {
	font-size: 0.8em;
	line-height: 3ch;
}
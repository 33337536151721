.material-info {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.material-image-section {
	flex: 1 1 200px;
	text-align: center;
}

.material-image {
	object-fit: contain;
	margin: 5px;
	height: 200px;
	width: 200px;
}

.material-form {
	flex: 2 1 400px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

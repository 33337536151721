#PayRunDetails {
	
}

.payrun-details {
	margin-bottom: 20px;
}

.payrun-details p {
	margin: 5px 0;
}

.status-button {
	padding: 5px 10px;
	margin: 0 5px;
	border-radius: 3px;
	color: white;
	font-weight: bold;
}

.status-button.yellow {
	background-color: #f0ad4e;
}

.status-button.grey {
	background-color: #6c757d;
}

.status-button.green {
	background-color: #5cb85c;
}

.status-button.red {
	background-color: #d9534f;
}

.print-table {
	display: none;
}

.print-table h1 {
	font-weight: bold;
	margin-bottom: 10px;
}

.print-table table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
}

.print-table th, .print-table td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
}

.print-table th {
	background-color: #f2f2f2;
    color: black;
}

@media print {
	.ag-theme-quartz {
		display: none;
	}
	.print-table {
		display: block;
	}
}

.Collapsible {
	border: 1px solid var(--color-border);
	margin-bottom: 30px;
	border-radius: 3px;
}

.Collapsible > .content {
	display: none;
	padding: 0.5em;
}
.Collapsible > .content.open {
	display: block;
}
.Collapsible .content-inner {
	padding: 5px;
	background-color: var(--color-collapsible-background);
}

.Collapsible .title {
	background-color: var(--color-collapsible-header);
	color: var(--color-collapsible-header-c);
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--color-border);
	align-items: center;
	font-weight: 400;
}

.collapsible-column-view {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}
.collapsible-column-view .Collapsible {
	flex-grow: 1;
    flex-basis: 0;
}

@media only screen and (max-width: 800px) {
	.collapsible-column-view {
		flex-direction: column;
		gap: 0;
	}
}

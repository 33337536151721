.file-input {
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  background-color: #eee;
  border: 1px solid grey;
  margin: 10px
}

.file-input:hover {
  background-color: #e9ecef;
}

.file-input::file-selector-button {
    display: inline-block;
	font-size: 1rem;
	padding: 7px 19px;
	border-radius: 3px 0 0 3px;
	background-color: var(--color-action-secondary);
	color: #ffffff;
	text-decoration: none;
    border: none;
    margin-right: 1rem;
}
.file-input::file-selector-button:hover {
    background-color: var(--color-action-secondary-alt);
}

.ImageUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.ImageUpload button {
    display: inline-block;
}

.job-form {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	padding: 20px;
	background-color: #ffffff;
	border-radius: 10px;
}

.job-form label {
	display: flex;
	flex-direction: column;
	font-size: 1em;
	color: #333;
	flex: 1 1 calc(50% - 20px); /* Adjust to fit two columns */
}

.job-form input {
	padding: 10px;
	font-size: 1em;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 5px;
}

.new-material-row {
	display: flex;
	gap: 20px;
	margin-top: 20px;
	max-width: 500px;
}

.new-material-row input,
.new-material-row > div,
.new-material-row .react-select__control {
	flex: 1;
	font-size: 16px;
	width: 100%;
	flex-grow: 1; /* Prevent resizing while typing */
}

.job-save-button:hover {
	background-color: #218838;
}

.ag-theme-quartz {
	margin-top: 20px;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.success-message {
	color: green;
	margin-left: 10px;
	font-weight: bold;
	display: inline-block;
}

.save-section {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.job-save-button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

/* Responsive styling */
@media (max-width: 768px) {
	.job-form {
		padding: 10px;
	}

	.job-form label {
		font-size: 14px;
		flex: 1 1 100%; /* Adjust to fit one column */
	}

	.job-form input {
		padding: 8px;
		font-size: 14px;
	}

	.new-material-row {
		flex-direction: column;
		gap: 10px;
	}

	.new-material-row input,
	.new-material-row .react-select__control {
		padding: 8px;
		font-size: 14px;
	}

	.new-material-row button {
		padding: 8px 16px;
		font-size: 14px;
	}
}

@media (max-width: 480px) {
	.job-form label {
		font-size: 12px;
	}

	.job-form input {
		padding: 6px;
		font-size: 12px;
	}

	.new-material-row input,
	.new-material-row .react-select__control {
		padding: 6px;
		font-size: 12px;
	}

	.new-material-row button {
		padding: 6px 12px;
		font-size: 12px;
	}
}

.customer-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
    background-color: #f9f9f9;
    border: 1px solid var(--color-border);
    padding: 1rem;
}

@media (max-width: 600px) {
	.customer-form {
		grid-template-columns: 1fr;
	}
}

.customer-form .form-group {
	display: flex;
	flex-direction: column;
}

.customer-form .form-group.full-width {
	grid-column: span 2;
}

.customer-form label {
	margin-bottom: 0.25rem;
}

.error-messages {
	color: red;
	margin-bottom: 0.5rem;
}

.quantity-over {
  color: red;
}

#CreateShipment {
}

.shipment-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.shipment-form label {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
}

.shipment-form input,
.shipment-form textarea {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.shipment-save-button {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
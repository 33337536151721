html,
body {
	position: fixed;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-color: var(--color-main-background);
	color: var(--color-content-text);
}

body {
	margin: 0;
	overflow: hidden;
	font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
		"Lucida Sans Unicode", Geneva, Verdana, sans-serif;
	font-size: 16px;
}

#app-main {
	display: flex;
	flex-wrap: wrap;
}

a {
	text-decoration: none;
	color: var(--color-action-secondary);
}

a:hover {
	font-weight: bold;
}
select{
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
}

button,
input[type="submit"] {
	display: inline-block;
	font-size: 1rem;
	padding: 7px 19px;
	border-radius: 3px;
	border: 1px solid grey;
	background-color: var(--color-action-secondary);
	color: #ffffff;
	text-decoration: none;
}

button.negative, .Button.negative,
input[type="submit"].negative {
	background-color: var(--color-action-negative);
}

input[type="submit"] {
	padding: 10px 23px;
	background-color: var(--color-action-primary);
	font-weight: bold;
}

button:hover:not(:disabled),
input[type="submit"]:hover:not(:disabled) {
	-webkit-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
	-moz-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
	box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
	cursor: pointer;
}

button.negative:hover:not(:disabled),
input[type="submit"].negative:hover:not(:disabled) {
	-webkit-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
	-moz-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
	box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.62);
	cursor: pointer;
}

button:disabled,
button[disabled],
input[type="submit"][disabled],
input[type="submit"]:disabled {
	border: 1px solid var(--color-action-disabled-c);
	background-color: var(--color-action-disabled);
	color: var(--color-action-disabled-c);
}

label {
	font-size: 0.8rem;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"].df.full-width,
select {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid var(--color-border);
	border-radius: 3px;
	box-sizing: border-box;
	color: var(--color-content-text)
}
div.buttonContainer.space {
	padding: 10px 0;
}
div.buttonContainer.right {
	text-align: right;
}
div.buttonContainer.left {
	text-align: left;
}
div.buttonContainer.full-width input {
	width: 100%;
}

form {
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 3px;
}
table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

table td,
table th {
	border: 1px solid var(--color-table-border);
	padding: 8px;
}

table tr:nth-child(even) {
	background-color: var(--color-table-alt-row);
}

table tr:hover {
	background-color: var(--color-table-hover-row);
}

table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: var(--color-secondary-alt);
	color: white;
}

h1, h2, h3, h4, h5, h6 {
	color: var(--color-secondary);
	margin: 0 0 10px 0;
}

h1 {
	font-size: 1.3rem;
	font-weight: bold;
}

h2 {
	font-size: 1.2rem;
	font-weight: bold;
}

h3 {
	font-size: 1.1rem;
	font-weight: bold;
}

h4 {
	font-size: 1rem;
	font-weight: bold;
}

h5 {
	font-size: 0.875rem;
	font-weight: bold;
}

h6 {
	font-size: 0.75rem;
	font-weight: bold;
}

/* Prevent mobile zoom on inputs */
@media screen and (max-width: 767px) {
	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="tel"],
	input[type="password"] {
		font-size: 16px;
	}
}
